import { gql } from '@apollo/client';
import {
  IInvestorOrganisationOutput,
  IInvestorUser
} from '@koble/koble-models';

const userProfileFragment = gql`
  fragment investorUserBasicFields on InvestorUser {
    id
    email
    firstName
    lastName
    role
    signupNotifications
    profilePicture
  }
`;

export const userQuery = gql`
  query investorUser {
    investorUser {
      ...investorUserBasicFields
      organisation {
        onboardedOn
        activatedOn
        name
        id
      }
    }
  }
  ${userProfileFragment}
`;

export const usersQuery = gql`
  query investorUsers {
    investorUsers {
      ...investorUserBasicFields
    }
  }
  ${userProfileFragment}
`;

export const loginMutation = gql`
  mutation investorUserLogin($email: String!, $redirectUrl: String!) {
    investorUserLogin(email: $email, redirectUrl: $redirectUrl) {
      id
    }
  }
`;

export const signupMutation = gql`
  mutation investorUserRegister($input: InvestorUserRegisterInput!) {
    investorUserRegister(input: $input) {
      id
    }
  }
`;

export const investorUserConfirmCodeMutation = gql`
  mutation investorUserConfirmCode(
    $email: String!
    $code: String!
    $redirect_url: String
    $channel: String
  ) {
    investorUserConfirmCode(
      email: $email
      code: $code
      redirect_url: $redirect_url
      channel: $channel
    ) {
      id
    }
  }
`;

export const userUpdateMutation = gql`
  mutation investorUserUpdate($input: InvestorUserInput!) {
    investorUserUpdate(input: $input) {
      ...investorUserBasicFields
    }
  }
  ${userProfileFragment}
`;

export const userUpdateRoleMutation = gql`
  mutation investorUserUpdateRole($id: String!, $role: Float!) {
    investorUserUpdate(input: { id: $id, role: $role }) {
      ...investorUserBasicFields
    }
  }
  ${userProfileFragment}
`;

export const userDeleteMutation = gql`
  mutation deleteinvestorUser($id: UUID!) {
    deleteInvestorUser(id: $id) {
      __typename
    }
  }
`;

export type User = Pick<
  IInvestorUser,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'profilePicture'
  | 'role'
  | 'signupNotifications'
> & {
  organisation: Pick<
    IInvestorOrganisationOutput,
    'activatedOn' | 'onboardedOn' | 'name' | 'id'
  >;
};
