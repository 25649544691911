import { getInvestorUserAnalyticsId } from '@koble/koble-models';
import { AnalyticsBrowser, AnalyticsNode } from '@segment/analytics-next';
import { createLogger } from '~/logging/logging';
import { isServer } from './getEnv';

interface IAnalytics {
  page(url: string): void;
  identify(traits: Traits): void;
  identify(userId: string, traits?: Traits): void;
  group(groupId: string, traits?: Traits): void;
  track(event: string, traits?: Traits): void;
}

let analytics: IAnalytics | undefined = undefined;

const logger = createLogger('analytics');

if (process.env.segmentApiKey) {
  const options = {
    writeKey: process.env.segmentApiKey
  };
  if (isServer()) {
    AnalyticsNode.load(options).then((result) => {
      analytics = result[0];
      logger.debug(`Using wrapped provider`);
    });
  } else {
    analytics = AnalyticsBrowser.load(options);
    logger.debug(`Using wrapped provider`);
  }
} else {
  logger.debug(`Write key missing, using logger`);
}

type Traits = Record<string, string | Date | number | Record<string, any>>;

export const getAnalytics = (): IAnalytics => {
  return {
    track: (event: string, traits?: Traits) => {
      logger.info('track', event);
      return analytics?.track(event, traits);
    },
    group: (groupId: string, traits?: Traits) => {
      logger.info('group', groupId);
      return analytics?.group(groupId, traits);
    },
    page: (url: string) => {
      logger.info('page', url);
      return analytics?.page(url);
    },
    identify: (userIdOrTraits: string | Traits, traits?: Traits) => {
      let userId: string;
      if (typeof userIdOrTraits === 'string') {
        userId = userIdOrTraits as string;
      } else {
        traits = userIdOrTraits as Traits;
        userId = traits.id as string;
      }
      logger.info('identify', userId, traits);
      return analytics?.identify(getInvestorUserAnalyticsId(userId), traits);
    }
  };
};

const uploadStartupEvent = 'uploadStartup';
const sourceStartupsEvent = 'sourceStartups';
const revealScoreEvent = 'revealScore';
const addToListEvent = 'addToShortlist';
const removeFromListEvent = 'removeFromShortlist';

export const AnalyticsEvents = {
  uploadStartupEvent,
  sourceStartupsEvent,
  revealScoreEvent,
  addToListEvent,
  removeFromListEvent
};
