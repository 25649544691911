import { useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal
} from '@chakra-ui/react';
import { INotification } from '@koble/koble-models';
import { BellIcon, ButtonLink, useLocalStorage } from '@koble/koble-ui-lib';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  markAllAsReadMutation,
  markAsReadMutation,
  notificationsQuery
} from '~/api/notifications';
import { useBrowserNotifications } from '~/hooks/browserNotifications';
import { NotificationRow } from './NotificationRow';

export const NotificationMenu = () => {
  const router = useRouter();

  const [markAsRead] = useMutation(markAsReadMutation);
  const [markAllAsRead] = useMutation(markAllAsReadMutation);

  const { sendNotification } = useBrowserNotifications();

  const [sentNotifications, setSentNotifications] = useLocalStorage<
    Record<string, boolean>
  >('sentNotifications', {});

  const { data: notificationsResponse, refetch } = useQuery<{
    investorNotifications: { items: INotification[] };
  }>(notificationsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { take: 10 },
    pollInterval: 5000
  });

  const notifications =
    notificationsResponse?.investorNotifications?.items || [];
  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const onNotificationClick = async (notification: INotification) => {
    if (!notification.isRead) {
      await markAsRead({ variables: { id: notification.id } });
      refetch();
    }
    router.push(`/ideas/${notification.idea.id}`);
  };

  useEffect(() => {
    notifications
      .filter((n) => !n.isRead && !sentNotifications[n.id])
      .forEach((n) => {
        const notification = sendNotification(n.idea.name || 'Koble', {
          icon: n.idea?.logoUrl || undefined,
          body: n.message
        });
        setSentNotifications({ ...sentNotifications, [n.id]: true });
        notification.addEventListener('click', () => {
          onNotificationClick(n);
        });
      });
  }, [notifications]);

  return (
    <Menu>
      {({ onClose }) => (
        <Box position="relative">
          <MenuButton
            as={IconButton}
            icon={<BellIcon boxSize="7" />}
            colorScheme="black"
            size="lg"
            aria-label="Notifications"
          ></MenuButton>
          {unreadCount > 0 && (
            <Badge
              colorScheme="red"
              variant="solid"
              borderRadius="full"
              boxSize="16px"
              fontSize="xs"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="0"
              right="0"
              pointerEvents="none"
              outline="3px solid black"
            >
              {unreadCount}
            </Badge>
          )}
          <Portal>
            <MenuList
              zIndex="popover"
              borderRadius="xs"
              py={0}
              boxShadow="popover"
            >
              <Flex
                alignItems="center"
                bg="gray.25"
                p={1}
                borderTopRadius="xs"
                justifyContent="space-between"
              >
                <Box fontWeight="bold" mx={2}>
                  Notifications
                </Box>
                <Button
                  variant="ghost"
                  colorScheme="purple"
                  isDisabled={notifications.length === 0}
                  onClick={async () => {
                    await markAllAsRead();
                    await refetch();
                  }}
                >
                  Mark all as read
                </Button>
              </Flex>
              {notifications.length === 0 && (
                <Box p={4} w="100%" fontWeight="bold" textAlign="center">
                  No unread notifications
                </Box>
              )}
              <Flex flexDirection="column" alignItems="stretch">
                {notifications.map((n) => (
                  <MenuItem
                    key={n.id}
                    p={0}
                    onClick={() => onNotificationClick(n)}
                  >
                    <NotificationRow notification={n} flex="1" />
                  </MenuItem>
                ))}
              </Flex>
              <Flex
                alignItems="center"
                bg="blue.50"
                py={1}
                borderBottomRadius="xs"
              >
                <ButtonLink
                  href="/notifications"
                  variant="ghost"
                  colorScheme="purple"
                  w="100%"
                  onClick={onClose}
                >
                  View all notifications
                </ButtonLink>
              </Flex>
            </MenuList>
          </Portal>
        </Box>
      )}
    </Menu>
  );
};
