import { UserRole } from '@koble/koble-models';
import * as Sentry from '@sentry/nextjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect } from 'react';
import { useKobleContext } from '~/hooks/kobleContext';
import { getAnalytics } from '~/utils/analytics';

export type UserAuth = {
  isAuthenticated: boolean;
  isActivated: boolean;
  loading: boolean;
  logout: () => Promise<any>;
  error?: any;
  user?: CognitoUser;
  role?: UserRole;
};

const UserAuthContext = createContext<UserAuth>({
  isAuthenticated: false,
  isActivated: false,
  logout: () => Promise.resolve(),
  loading: true
});

const analytics = getAnalytics();

export const useUserAuth = () => useContext(UserAuthContext);

type UserAuthProviderProps = {
  children: React.ReactNode;
};

export const UserAuthProvider = ({ children }: UserAuthProviderProps) => {
  const { error, cognitoUser, user, isPending, userLoading, authStatus } =
    useKobleContext();

  const router = useRouter();
  const ldClient = useLDClient();

  if (error) console.warn(error);

  useEffect(() => {
    if (!isPending && cognitoUser && user) {
      analytics.group(user.organisation.id, {
        source: 'investor',
        organisationName: user.organisation.name
      });
      analytics.identify(user.id, { source: 'investor' });
      Sentry.setUser({
        id: user.id,
        email:
          cognitoUser.getSignInUserSession()?.getIdToken()?.decodePayload()
            ?.email || undefined
      });
    }
  }, [cognitoUser, user, isPending]);

  useEffect(() => {
    if (!isPending && user?.organisation?.name && ldClient) {
      ldClient.identify({
        key: user.organisation.id,
        name: user.organisation.name /*,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName*/
      });
    }
  }, [user?.organisation?.name, ldClient, isPending]);

  useEffect(() => {
    if (
      authStatus !== 'authenticated' &&
      isPending !== true &&
      !userLoading &&
      router.route.indexOf('login') === -1 &&
      router.route.indexOf('confirm') === -1 &&
      router.route.indexOf('invite') === -1 &&
      router.route.indexOf('login-error') === -1 &&
      router.isReady
    ) {
      router.replace(
        `/login${
          router.route !== '/logout'
            ? `?onLoginUrl=${encodeURIComponent(router.asPath)}`
            : ''
        }`
      );
    }
  }, [isPending, authStatus, userLoading, error, router.route, router.isReady]);

  // useEffect(() => {
  //   if (!loading && error) {
  //     router.push('/login-error');
  //   }
  // }, [error, loading]);

  return <>{children}</>;
};
