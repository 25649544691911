/* istanbul ignore file */
import { createEnvConfig } from '@koble/koble-ui-lib';
import clientEnv from '../client-env.json';

const {
  getHost,
  getEnv,
  getAppUrl,
  getApiUrl,
  isServer,
  getRelease,
  isDevelopment,
  isProduction,
  isCI,
  isTesting,
  getSentryDSN,
  getLaunchDarklyClientSideId
} = createEnvConfig({ clientEnv, appName: 'investor' });

export {
  getApiUrl,
  getAppUrl,
  getEnv,
  getHost,
  getLaunchDarklyClientSideId,
  getRelease,
  getSentryDSN,
  isCI,
  isDevelopment,
  isProduction,
  isServer,
  isTesting
};
